export const text = {
  no: "NEI",
  soon: "Det nærmer seg...",
  yes: "DET ER HELG!",
  noPre: "Det er fremdeles",
  noPost: "igjen til helg...",
  soonPre: "Det er bare",
  soonPost: "igjen til helg!",
  days: "Dager",
  hours: "Timer",
  minutes: "Minutter",
  seconds: "Sekunder"
};
